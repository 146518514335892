<template>
  <div class="wrapper">
    <!-- <div class="page-header page-header-small header-filter">
      <div
        class="page-header-image"
        :style="
          `background-image: url(${require('@/assets/img/pages/nicolas-prieto.jpg')});`
        "
      ></div>
      <div class="content-center">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h1 class="title text-white">
              A Place for Entrepreneurs to Share and Discover New Stories
            </h1>
            <base-button
              type="warning"
              icon="fa fa-twitter"
              rounded
              icon-only
            ></base-button>
            <base-button
              type="warning"
              icon="fa fa-instagram"
              rounded
              icon-only
            ></base-button>
          </div>
        </div>
      </div>
    </div> -->
    <section class="blogs-3 mt-8">
      <div class="container">
        <div class="row">
          <div v-if="loading" class="text-center mx-auto">
            <spinner class="text-primary spinner-border-lg mb-5"></spinner>
          </div>
          <div v-else class="col-lg-10 col-md-8 mx-auto">
            <div v-if="blog.count == 0" class="text-center">
              <h1 class="display-3 mt-6 mb-6">No blog posts found</h1>
              <!-- <router-link to="/"
                ><base-button outline
                  >Home <span class="fa fa-home"></span></base-button
              ></router-link> -->
            </div>
            <div v-else>
              <h2 class="title mb-5">Latest Blogposts</h2>
              <Blog v-for="blog in blogs" :key="blog.id" :data="blog" />
            </div>
            <div class="text-center pb-3">
              <base-button
                v-if="blog.next"
                class="btn-round"
                outline
                @click="loadMore()"
                >Load More</base-button
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner";
const Blog = () => import(/* webpackChunkName: "Blog"*/ "../components/Blog");
export default {
  metaInfo() {
    return {
      title: "Blog"
    };
  },
  data: function() {
    return {
      blogs: [],
      blog: {
        count: null,
        next: null,
        previous: null
      },
      page: 1,
      loading: true
    };
  },
  components: {
    Blog,
    Spinner
  },
  mounted() {
    this.updateBlogs();
  },
  methods: {
    updateBlogs() {
      this.loading = true;
      this.$axios
        .get("blog/posts/?page=" + this.page)
        .then(res => {
          this.blog.count = res.data.count;
          this.blog.next = res.data.next;
          this.blog.previous = res.data.previous;
          this.blogs.push(...res.data.results);
          this.loading = false;
        })
        .catch(function() {
          this.loading = false;
        });
    },
    loadMore() {
      this.page++;
      this.updateBlogs();
    }
  }
};
</script>
<style></style>
